export const state = () => ({
  categories: [],
  error_message: '',
})

export const mutations = {
  setCategory(state, categories) {
    state.categories = categories
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getCategories({ commit }) {
    await this.$axios
      .$get('/category')
      .then((response) => {
        let categories = response
        commit('setCategory', categories)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
