export default {
  computed: {
    currency() {
      return this.$cookies.get('currency') || 'TWD'
    },
    currencyDictionary() {
      return {
        // JPY: '￥',
        TWD: 'NT$',
      }
    },
    currencyTexts() {
      return Object.keys(this.currencyDictionary)
    },
  },
  methods: {
    getCurrency(price = 0, currency) {
      return `${this.currentCurrencyIcon(currency)} ${this.transPrice(price)}`
    },
    transPrice(price = 0, decimal = 0, sections = 3) {
      const re = '\\d(?=(\\d{' + (sections || 3) + '})+' + (decimal > 0 ? '\\.' : '$') + ')'
      return price.toFixed(Math.max(0, ~~decimal)).replace(new RegExp(re, 'g'), '$&,')
    },
    currentCurrencyIcon(currency) {
      if (currency) {
        return this.currencyDictionary[currency] || ''
      }

      return this.currencyDictionary[this.currency] || ''
    },
    changeCurrency(currency) {
      this.$cookies.set('currency', currency)
      window.location.reload()
    },
  },
}
