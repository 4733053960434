//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CurrencyMixin from '@/mixins/currency'

export default {
  mixins: [CurrencyMixin],
  props: {
    quickSearch: {
      type: Boolean,
      default: false,
    },

    hotTagsGroup: {
      type: Array,
      default() {
        return []
      },
    },

    data: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  methods: {
    submit(e) {
      this.$emit('submit', e.target.dataset.value)
    },
  },
}
