//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import DropdownUser from '@/components/header/dropdown/user'
import DropdownMessage from '@/components/header/dropdown/message'
import DropdownCart from '@/components/header/dropdown/cart'
// import DropdownCurrency from '@/components/header/dropdown/currency'
import DropdownLocalization from '@/components/header/dropdown/localization'
import AuthModal from '@/components/auth'
import VerifyEmailModal from '@/components/modal/VerifyEmailModal'
import Menu from '@/components/user/Menu'
import SearchBar from '@/components/search/SearchBar'
import Logo from '@/components/shared/logo-simplified'
import AuthMixin from '@/mixins/auth'

export default {
  components: {
    DropdownUser,
    DropdownCart,
    DropdownMessage,
    // DropdownCurrency,
    DropdownLocalization,
    AuthModal,
    VerifyEmailModal,
    Logo,
    Menu,
    SearchBar,
  },
  mixins: [AuthMixin],
  props: {
    hideInMobile: {
      type: Boolean,
      default: false,
    },

    productIsFavorite: {
      type: Boolean,
      default: false,
    },

    settings: {
      type: Object,
      default() {
        return {
          fixed: false,
          transparent: false,
        }
      },
    },

    productId: {
      type: Number,
      default: null,
    },

    isFavorite: {
      type: Boolean,
      default: false,
    },

    wishlist: {
      type: Array,
      default() {
        return [
          {
            id: null,
            product_id: null,
          },
        ]
      },
    },

    searchActive: {
      type: Boolean,
      default: false,
    },
  },
  fetch() {
    this.initCurrency()
  },
  data() {
    return {
      logo_width: 31,
      navbar: {
        fixed: false,
        transparent: this.index ? true : false,
        collapsed: false,
      },
      isHearted: this.isFavorite,
    }
  },
  computed: {
    ...mapGetters({
      products: 'cart/list',
      notificationCount: 'user/notification/count',
    }),

    wishListId() {
      return this.wishlist.length ? this.wishlist[0].id : null
    },

    search_wrap: {
      get() {
        return this.searchActive
      },
      set(newValue) {
        this.$emit('update:searchActive', newValue)
      },
    },
    hasNotification() {
      return this.notificationCount > 0
    },
  },

  watch: {
    productIsFavorite(val) {
      return val
    },
  },
  mounted() {
    const user_id = _.get(this.$store, 'state.auth.user.id')
    if (user_id) {
      // Create notification websockets
      const channelId = `User.${user_id}.Notification`
      window.Echo.private(channelId).listen('.SendNotification', (response) => {
        const notification = response.data
        this.addNotification(notification)
      })

      this.fetchUnreadCount()
    }

    this.initCart()

    const { modal } = this.$route.query
    if (modal === 'sign' && !user_id) {
      this.$bvModal.show('Login')
    }
  },
  methods: {
    ...mapActions({
      initCart: 'cart/initCart',
      addNotification: 'user/notification/addNotification',
      fetchUnreadCount: 'user/notification/fetchUnreadCount',
    }),
    initCurrency() {
      const currency = this.$cookies.get('currency')
      if (!currency) {
        this.$cookies.set('currency', 'TWD')
      }
    },
    toggleCollapsedMenu() {
      this.navbar.collapsed = !this.navbar.collapsed
      this.$nuxt.$emit('header-collpased', this.navbar.collapsed)
    },

    handletoggleHeart() {
      this.$emit('toggleHandler')
    },

    handleSearchWrap(val) {
      this.search_wrap = val
    },

    closeSearchWrap() {
      this.$refs.SearchBar.closePanel()
    },

    showSearchWrap() {
      this.$refs.SearchBar.showPanel()
    },

    // 移除或加入我的最愛後重新取得單一商品資料
    getProductWishList() {
      this.$store.dispatch('product/detail/getProduct', {
        id: this.$route.params.show,
      })
    },
    toggleHeart(value, id, wishListId) {
      if (this.$store.state.auth.loggedIn) {
        const is_favorite = !value

        // 加入最愛
        if (is_favorite) {
          this.$store.dispatch('product/wishlist/store', {
            id: id,
          })

          this.isHearted = true
          return
        }

        // 移除最愛
        if (!is_favorite) {
          this.$store.dispatch('product/wishlist/destroy', {
            id: wishListId,
            product_id: this.productId,
          })

          this.isHearted = false
        }
        return
      }

      // 更新 wishlist vuex 資料
      this.getProductWishList()

      this.$toast.error(this.$t('toast.no_logged_in'))
      // show AuthModal
      this.$bvModal.show('Login')
    },
  },
}
