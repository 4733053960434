//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import authMixins from '@/mixins/auth'

export default {
  mixins: [authMixins],

  props: {
    selectedItem: {
      type: String,
      default: '',
    },
    fallCollpased: {
      type: Boolean,
      default: false,
    },
    pendingComments: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    name() {
      return this.authUserName
    },
    avatarUrl() {
      return this.authUser.avatar_url
    },

    socialAuth() {
      return this.auth.user.social && this.auth.user.social.length ? true : false
    },

    loggedWithSocial() {
      return this.auth.user.logged_in_method != 'GENERAL'
    },

    /**
     * @description: (facebook,google...) first char conver to UpperCase.
     * @param {*}
     * @return {*}
     */
    socialProvider() {
      return (
        this.auth.user.social[0].provider.charAt(0).toUpperCase() +
        this.auth.user.social[0].provider.substring(1)
      )
    },

    list() {
      return [
        {
          key: 'info',
          icon: 'fa-user',
          show: true,
        },
        {
          key: 'favorite',
          icon: 'fa-heart',
          show: true,
        },
        {
          key: 'order',
          icon: 'fa-clipboard-list',
          show: true,
        },
        {
          key: 'comment',
          icon: 'fa-star-half-alt',
          show: true,
        },
        {
          key: 'message',
          icon: 'fas fa-envelope-open-text',
          show: true,
        },
        {
          key: 'notification',
          icon: 'fa-bell',
          show: true,
        },
        {
          key: 'reset_password',
          icon: 'fa-key',
          show: !this.loggedWithSocial,
        },
        {
          key: 'referrer',
          icon: 'fa-ticket-alt',
          show: true,
        },
      ]
    },
  },

  methods: {
    /**
     * Handle select item
     * @param {any} key
     */
    handleSelect(key) {
      this.$emit('update:selectedItem', key)
    },
    /**
     * Get item class name
     * @param {any} key
     * @returns {any}
     */
    itemClass(key) {
      return {
        active: key === this.$route.params.user,
      }
    },
    /**
     * Handle logout
     */
    handleLogout() {
      this.$auth.logout()
      this.$nuxt.$emit('header-collpased', false)
    },
  },
}
