export const state = () => ({
  trend_cities: [
    {
      id: 1,
      name: '大阪',
      picture: process.env.API_ROUTER_URL + '/img/hot_city/osaka.jpg',
    },
    {
      id: 2,
      name: '京都',
      picture: process.env.API_ROUTER_URL + '/img/hot_city/kyoto.jpg',
    },
    {
      id: 3,
      name: '神戶',
      picture: process.env.API_ROUTER_URL + '/img/hot_city/kobe.jpg',
    },
    {
      id: 4,
      name: '奈良',
      picture: process.env.API_ROUTER_URL + '/img/hot_city/nara.jpg',
    },
    {
      id: 5,
      name: '和歌山',
      picture: process.env.API_ROUTER_URL + '/img/hot_city/wakayama.jpg',
    },
  ],
  error_message: '',
})

export const mutations = {
  setTrendCities(state, trend_cities) {
    state.trend_cities = trend_cities
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getTrendCities({ commit }) {
    await this.$axios
      .$get('/trend_cities/list')
      .then((response) => {
        let trend_cities = response
        commit('setTrendCities', trend_cities)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
