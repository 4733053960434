//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
// import Oauth from "@/components/auth/oauth"
export default {
  components: {
    // Oauth,
  },
  data() {
    return {
      input: {
        email: '',
        password: '',
      },
      error_msg: {
        detail: {},
      },
      loginable: true,
      lock_minutes: '',
    }
  },

  computed: {
    ...mapState('auths/login', ['last_login_error', 'count']),
  },

  methods: {
    ...mapActions({
      userLogin: 'auths/login/userLogin',
      getWishlist: 'product/wishlist/getWishlist',
      resetWishlist: 'product/wishlist/resetWishlist',
    }),

    handleContent(mode) {
      this.$emit('handler', mode)
    },
    handleShowPwd(e) {
      const status = e.target.className
      const el = [...e.target.offsetParent.children]
      const _target = el.find((element) => element.localName == 'input')

      let _type, _class

      if (status == 'fa fa-eye') {
        _type = 'password'
        _class = 'fa fa-eye-slash'
      } else if (status == 'fa fa-eye-slash') {
        _type = 'text'
        _class = 'fa fa-eye'
      }
      _target.setAttribute('type', _type)
      e.target.className = _class
    },
    async handleLogin() {
      try {
        await this.$auth.loginWith('local', {
          data: {
            email: this.input.email,
            password: this.input.password,
          },
        })
        this.$toast.success(this.$t('signin.toast.success'))

        this.getWishlist()

        this.$emit('handler', 'close')

        this.userLogin('SUCCESSED')
      } catch (e) {
        this.error_msg = e
        this.$toast.error(this.$t('signin.toast.error'))

        this.userLogin('FAILED')
      }
    },
    /**
     * @description: Oauth
     * @param {*} social
     * @return {*}
     */
    async oauthLogin(social) {
      const result = await this.$axios.get(`auth/${social}`)
      window.location.href = result.data
    },
  },
}
