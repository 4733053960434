import _ from 'lodash'

export const state = () => ({
  recipients: [],
  error_message: '',
})

export const getters = {
  recipients(state) {
    return state.recipients
  },
}

export const mutations = {
  setRecipients(state, recipients) {
    state.recipients = recipients
    return state.recipients
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async fetchRecipients({ commit }) {
    try {
      const response = await this.$axios.get('/recipient')
      const recipients = _.get(response, 'data', [])

      commit('setRecipients', recipients)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
}
