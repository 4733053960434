export const state = () => ({
  trend_group: {},
  error_message: '',
})

export const mutations = {
  setTrendGroup(state, trend_group) {
    state.trend_group = trend_group
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getTrendGroup({ commit }, { id }) {
    await this.$axios
      .$get(`/trend_group/${id}`)
      .then((response) => {
        let trend_group = response.data
        commit('setTrendGroup', trend_group)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
