export const state = () => ({
  last_send: null,
})

export const mutations = {
  setTime(state) {
    state.last_send = new Date()
  },
}

export const actions = {
  async postForgetPassword({ commit }) {
    commit('setTime')
  },
}
