//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex'
import CurrencyMixin from '@/mixins/currency'

export default {
  mixins: [CurrencyMixin],
  computed: {
    ...mapGetters({
      products: 'cart/list',
    }),
  },
  methods: {
    ...mapActions({
      updateProductQuantity: 'cart/updateProductQuantity',
      removeProductFromCart: 'cart/removeProductFromCart',
    }),
    onOver() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.show()
      }
    },
    onLeave() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.hide()
      }
    },
    /**
     * @description: Show confirm for delete product in cart.
     * @param {*}
     * @return {*}
     */
    handleDelete(item_id) {
      this.$bvModal
        .msgBoxConfirm(this.$t('car.confirm_delete'), {
          title: this.$t('delete'),
          buttonSize: 'sm',
          centered: true,
          size: 'sm',
          okTitle: this.$t('confirm'),
          cancelTitle: this.$t('cancel'),
        })
        .then((value) => {
          if (!value) {
            return
          }
          this.removeProductFromCart(item_id)
        })
    },
  },
}
