//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  methods: {
    dismiss() {
      this.$emit('dismiss')
    },
  },
}
