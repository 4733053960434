import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _acd60316 = () => interopDefault(import('../pages/404/index.vue' /* webpackChunkName: "pages/404/index" */))
const _25f30a59 = () => interopDefault(import('../pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _3d6810e8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _449b5cc3 = () => interopDefault(import('../pages/payment/index.vue' /* webpackChunkName: "pages/payment/index" */))
const _65b7220c = () => interopDefault(import('../pages/auth/reset_password.vue' /* webpackChunkName: "pages/auth/reset_password" */))
const _6b0c4674 = () => interopDefault(import('../pages/auth/social-callback.vue' /* webpackChunkName: "pages/auth/social-callback" */))
const _f2bc145c = () => interopDefault(import('../pages/auth/social-register.vue' /* webpackChunkName: "pages/auth/social-register" */))
const _00955577 = () => interopDefault(import('../pages/product/list/index.vue' /* webpackChunkName: "pages/product/list/index" */))
const _3307f7f4 = () => interopDefault(import('../pages/product/detail/_show.vue' /* webpackChunkName: "pages/product/detail/_show" */))
const _2fe47c36 = () => interopDefault(import('../pages/product/search/_search.vue' /* webpackChunkName: "pages/product/search/_search" */))
const _4bf1b2f0 = () => interopDefault(import('../pages/purchase/_no.vue' /* webpackChunkName: "pages/purchase/_no" */))
const _6497b11c = () => interopDefault(import('../pages/user/_user.vue' /* webpackChunkName: "pages/user/_user" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/404",
    component: _acd60316,
    name: "404___zh_tw"
  }, {
    path: "/cart",
    component: _25f30a59,
    name: "cart___zh_tw"
  }, {
    path: "/en",
    component: _3d6810e8,
    name: "index___en"
  }, {
    path: "/ja",
    component: _3d6810e8,
    name: "index___ja"
  }, {
    path: "/payment",
    component: _449b5cc3,
    name: "payment___zh_tw"
  }, {
    path: "/auth/reset_password",
    component: _65b7220c,
    name: "auth-reset_password___zh_tw"
  }, {
    path: "/auth/social-callback",
    component: _6b0c4674,
    name: "auth-social-callback___zh_tw"
  }, {
    path: "/auth/social-register",
    component: _f2bc145c,
    name: "auth-social-register___zh_tw"
  }, {
    path: "/en/404",
    component: _acd60316,
    name: "404___en"
  }, {
    path: "/en/cart",
    component: _25f30a59,
    name: "cart___en"
  }, {
    path: "/en/payment",
    component: _449b5cc3,
    name: "payment___en"
  }, {
    path: "/ja/404",
    component: _acd60316,
    name: "404___ja"
  }, {
    path: "/ja/cart",
    component: _25f30a59,
    name: "cart___ja"
  }, {
    path: "/ja/payment",
    component: _449b5cc3,
    name: "payment___ja"
  }, {
    path: "/product/list",
    component: _00955577,
    name: "product-list___zh_tw"
  }, {
    path: "/en/auth/reset_password",
    component: _65b7220c,
    name: "auth-reset_password___en"
  }, {
    path: "/en/auth/social-callback",
    component: _6b0c4674,
    name: "auth-social-callback___en"
  }, {
    path: "/en/auth/social-register",
    component: _f2bc145c,
    name: "auth-social-register___en"
  }, {
    path: "/en/product/list",
    component: _00955577,
    name: "product-list___en"
  }, {
    path: "/ja/auth/reset_password",
    component: _65b7220c,
    name: "auth-reset_password___ja"
  }, {
    path: "/ja/auth/social-callback",
    component: _6b0c4674,
    name: "auth-social-callback___ja"
  }, {
    path: "/ja/auth/social-register",
    component: _f2bc145c,
    name: "auth-social-register___ja"
  }, {
    path: "/ja/product/list",
    component: _00955577,
    name: "product-list___ja"
  }, {
    path: "/en/product/detail/:show?",
    component: _3307f7f4,
    name: "product-detail-show___en"
  }, {
    path: "/en/product/search/:search?",
    component: _2fe47c36,
    name: "product-search-search___en"
  }, {
    path: "/ja/product/detail/:show?",
    component: _3307f7f4,
    name: "product-detail-show___ja"
  }, {
    path: "/ja/product/search/:search?",
    component: _2fe47c36,
    name: "product-search-search___ja"
  }, {
    path: "/en/purchase/:no?",
    component: _4bf1b2f0,
    name: "purchase-no___en"
  }, {
    path: "/en/user/:user?",
    component: _6497b11c,
    name: "user-user___en"
  }, {
    path: "/ja/purchase/:no?",
    component: _4bf1b2f0,
    name: "purchase-no___ja"
  }, {
    path: "/ja/user/:user?",
    component: _6497b11c,
    name: "user-user___ja"
  }, {
    path: "/product/detail/:show?",
    component: _3307f7f4,
    name: "product-detail-show___zh_tw"
  }, {
    path: "/product/search/:search?",
    component: _2fe47c36,
    name: "product-search-search___zh_tw"
  }, {
    path: "/purchase/:no?",
    component: _4bf1b2f0,
    name: "purchase-no___zh_tw"
  }, {
    path: "/user/:user?",
    component: _6497b11c,
    name: "user-user___zh_tw"
  }, {
    path: "/",
    component: _3d6810e8,
    name: "index___zh_tw"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
