//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  data: () => {
    return {
      loading: false,
      throttle: 0,
      blur: '1px',
      opacity: '0.7',
      variant: 'light',
      rounded: 'lg',
    }
  },
  methods: {
    start() {
      this.loading = true
    },
    finish() {
      setTimeout(() => {
        this.loading = false
      }, 300)
    },
  },
}
