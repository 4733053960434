export const state = () => ({
  product_recommends: [],
  error_message: '',
})

export const mutations = {
  setProductRecommend(state, product_recommends) {
    state.product_recommends = product_recommends
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getProductRecommend({ commit }, payload) {
    await this.$axios
      .$get(`/product/${payload.id}/recommend`, {
        params: { category_id: payload.category_id },
      })
      .then((response) => {
        let product_recommends = response
        commit('setProductRecommend', product_recommends)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
