//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Login from '@/components/auth/login'
import Register from '@/components/auth/register'
import ForgetPassword from '@/components/auth/forgetPassword'

export default {
  components: {
    Login,
    Register,
    ForgetPassword,
  },
  props: {
    active: Boolean,
  },
  data() {
    return {
      mode: 'login',
      modal_title: 'login.title',
    }
  },
  watch: {
    mode(val) {
      switch (val) {
        case 'login':
          this.modal_title = 'login.title'
          break
        case 'forget_password':
          this.modal_title = 'menu.forget_password'
          break
        case 'register':
          this.modal_title = 'register.title'
          break
      }
    },
  },
  mounted() {
    /** listening on modal show set default mode `login` */
    this.$root.$on('bv::modal::show', () => {
      this.mode = 'login'
      this.$nuxt.$emit('toggle-login', true)
    })
    this.$root.$on('bv::modal::hide', () => {
      this.$nuxt.$emit('toggle-login', false)
    })
  },
  methods: {
    /**
     * Handling modal content mode.
     * @constructor
     * @param {mode} - Using `login`, `forget_password`, `register` and `close`.
     */
    handleContent(mode) {
      if (mode == 'close') {
        this.$refs.modal.hide()
      } else {
        this.mode = mode
      }
    },
    /**
     * Closing Modal button in modal header.
     * If not `login` mode, button click direct to `login` mode
     * @constructor
     */
    close() {
      if (this.mode != 'login') {
        this.mode = 'login'
      } else {
        this.$refs.modal.hide()
      }
    },
  },
}
