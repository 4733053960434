export const Breadcrumb = () => import('../../components/Breadcrumb.vue' /* webpackChunkName: "components/breadcrumb" */).then(c => wrapFunctional(c.default || c))
export const Loading = () => import('../../components/Loading.vue' /* webpackChunkName: "components/loading" */).then(c => wrapFunctional(c.default || c))
export const RequiredStar = () => import('../../components/RequiredStar.vue' /* webpackChunkName: "components/required-star" */).then(c => wrapFunctional(c.default || c))
export const Steps = () => import('../../components/Steps.vue' /* webpackChunkName: "components/steps" */).then(c => wrapFunctional(c.default || c))
export const BoxTableBox = () => import('../../components/Box/TableBox.vue' /* webpackChunkName: "components/box-table-box" */).then(c => wrapFunctional(c.default || c))
export const Auth = () => import('../../components/auth/index.vue' /* webpackChunkName: "components/auth" */).then(c => wrapFunctional(c.default || c))
export const CartArrivalInfoSection = () => import('../../components/cart/ArrivalInfoSection.vue' /* webpackChunkName: "components/cart-arrival-info-section" */).then(c => wrapFunctional(c.default || c))
export const CartCompleteItem = () => import('../../components/cart/CompleteItem.vue' /* webpackChunkName: "components/cart-complete-item" */).then(c => wrapFunctional(c.default || c))
export const CartCompleteSection = () => import('../../components/cart/CompleteSection.vue' /* webpackChunkName: "components/cart-complete-section" */).then(c => wrapFunctional(c.default || c))
export const CartCouponSection = () => import('../../components/cart/CouponSection.vue' /* webpackChunkName: "components/cart-coupon-section" */).then(c => wrapFunctional(c.default || c))
export const CartEmptyCart = () => import('../../components/cart/EmptyCart.vue' /* webpackChunkName: "components/cart-empty-cart" */).then(c => wrapFunctional(c.default || c))
export const CartInvoiceSection = () => import('../../components/cart/InvoiceSection.vue' /* webpackChunkName: "components/cart-invoice-section" */).then(c => wrapFunctional(c.default || c))
export const CartLogisticsSection = () => import('../../components/cart/LogisticsSection.vue' /* webpackChunkName: "components/cart-logistics-section" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentInfo = () => import('../../components/cart/PaymentInfo.vue' /* webpackChunkName: "components/cart-payment-info" */).then(c => wrapFunctional(c.default || c))
export const CartPaymentMethodSection = () => import('../../components/cart/PaymentMethodSection.vue' /* webpackChunkName: "components/cart-payment-method-section" */).then(c => wrapFunctional(c.default || c))
export const CartProductItem = () => import('../../components/cart/ProductItem.vue' /* webpackChunkName: "components/cart-product-item" */).then(c => wrapFunctional(c.default || c))
export const CartProductTable = () => import('../../components/cart/ProductTable.vue' /* webpackChunkName: "components/cart-product-table" */).then(c => wrapFunctional(c.default || c))
export const CartRecipientSection = () => import('../../components/cart/RecipientSection.vue' /* webpackChunkName: "components/cart-recipient-section" */).then(c => wrapFunctional(c.default || c))
export const Cookie = () => import('../../components/cookie/index.vue' /* webpackChunkName: "components/cookie" */).then(c => wrapFunctional(c.default || c))
export const Dock = () => import('../../components/dock/index.vue' /* webpackChunkName: "components/dock" */).then(c => wrapFunctional(c.default || c))
export const Error = () => import('../../components/error/404.vue' /* webpackChunkName: "components/error" */).then(c => wrapFunctional(c.default || c))
export const Footer = () => import('../../components/footer/index.vue' /* webpackChunkName: "components/footer" */).then(c => wrapFunctional(c.default || c))
export const FormCDatepicker = () => import('../../components/form/CDatepicker.vue' /* webpackChunkName: "components/form-c-datepicker" */).then(c => wrapFunctional(c.default || c))
export const FormCInput = () => import('../../components/form/CInput.vue' /* webpackChunkName: "components/form-c-input" */).then(c => wrapFunctional(c.default || c))
export const FormCRadio = () => import('../../components/form/CRadio.vue' /* webpackChunkName: "components/form-c-radio" */).then(c => wrapFunctional(c.default || c))
export const FormCSelect = () => import('../../components/form/CSelect.vue' /* webpackChunkName: "components/form-c-select" */).then(c => wrapFunctional(c.default || c))
export const FormCounter = () => import('../../components/form/Counter.vue' /* webpackChunkName: "components/form-counter" */).then(c => wrapFunctional(c.default || c))
export const FormRangeInput = () => import('../../components/form/RangeInput.vue' /* webpackChunkName: "components/form-range-input" */).then(c => wrapFunctional(c.default || c))
export const FormDropdownSelector = () => import('../../components/form/dropdownSelector.vue' /* webpackChunkName: "components/form-dropdown-selector" */).then(c => wrapFunctional(c.default || c))
export const FormRangeSlider = () => import('../../components/form/rangeSlider.vue' /* webpackChunkName: "components/form-range-slider" */).then(c => wrapFunctional(c.default || c))
export const Header = () => import('../../components/header/index.vue' /* webpackChunkName: "components/header" */).then(c => wrapFunctional(c.default || c))
export const ModalOrderModal = () => import('../../components/modal/OrderModal.vue' /* webpackChunkName: "components/modal-order-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalRecipientModal = () => import('../../components/modal/RecipientModal.vue' /* webpackChunkName: "components/modal-recipient-modal" */).then(c => wrapFunctional(c.default || c))
export const ModalVerifyEmailModal = () => import('../../components/modal/VerifyEmailModal.vue' /* webpackChunkName: "components/modal-verify-email-modal" */).then(c => wrapFunctional(c.default || c))
export const SharedLogoSimplified = () => import('../../components/shared/logo-simplified.vue' /* webpackChunkName: "components/shared-logo-simplified" */).then(c => wrapFunctional(c.default || c))
export const SharedLogo = () => import('../../components/shared/logo.vue' /* webpackChunkName: "components/shared-logo" */).then(c => wrapFunctional(c.default || c))
export const UserMenu = () => import('../../components/user/Menu.vue' /* webpackChunkName: "components/user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserMessageBox = () => import('../../components/user/MessageBox.vue' /* webpackChunkName: "components/user-message-box" */).then(c => wrapFunctional(c.default || c))
export const AuthForgetPassword = () => import('../../components/auth/forgetPassword/index.vue' /* webpackChunkName: "components/auth-forget-password" */).then(c => wrapFunctional(c.default || c))
export const AuthLogin = () => import('../../components/auth/login/index.vue' /* webpackChunkName: "components/auth-login" */).then(c => wrapFunctional(c.default || c))
export const AuthRegister = () => import('../../components/auth/register/index.vue' /* webpackChunkName: "components/auth-register" */).then(c => wrapFunctional(c.default || c))
export const CityPopular = () => import('../../components/city/popular/index.vue' /* webpackChunkName: "components/city-popular" */).then(c => wrapFunctional(c.default || c))
export const CommentCardHistory = () => import('../../components/comment/card/history.vue' /* webpackChunkName: "components/comment-card-history" */).then(c => wrapFunctional(c.default || c))
export const CommentCard = () => import('../../components/comment/card/index.vue' /* webpackChunkName: "components/comment-card" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownCart = () => import('../../components/header/dropdown/cart.vue' /* webpackChunkName: "components/header-dropdown-cart" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownCurrency = () => import('../../components/header/dropdown/currency.vue' /* webpackChunkName: "components/header-dropdown-currency" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownLocalization = () => import('../../components/header/dropdown/localization.vue' /* webpackChunkName: "components/header-dropdown-localization" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownMessage = () => import('../../components/header/dropdown/message.vue' /* webpackChunkName: "components/header-dropdown-message" */).then(c => wrapFunctional(c.default || c))
export const HeaderDropdownUser = () => import('../../components/header/dropdown/user.vue' /* webpackChunkName: "components/header-dropdown-user" */).then(c => wrapFunctional(c.default || c))
export const HeaderSection = () => import('../../components/header/section/index.vue' /* webpackChunkName: "components/header-section" */).then(c => wrapFunctional(c.default || c))
export const OrderCardHistory = () => import('../../components/order/card/history.vue' /* webpackChunkName: "components/order-card-history" */).then(c => wrapFunctional(c.default || c))
export const OrderCard = () => import('../../components/order/card/index.vue' /* webpackChunkName: "components/order-card" */).then(c => wrapFunctional(c.default || c))
export const ProductCardFavorite = () => import('../../components/product/card/favorite.vue' /* webpackChunkName: "components/product-card-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductCard = () => import('../../components/product/card/index.vue' /* webpackChunkName: "components/product-card" */).then(c => wrapFunctional(c.default || c))
export const ProductEmpty = () => import('../../components/product/card/productEmpty.vue' /* webpackChunkName: "components/product-empty" */).then(c => wrapFunctional(c.default || c))
export const ProductCart = () => import('../../components/product/detail/ProductCart.vue' /* webpackChunkName: "components/product-cart" */).then(c => wrapFunctional(c.default || c))
export const ProductComments = () => import('../../components/product/detail/ProductComments.vue' /* webpackChunkName: "components/product-comments" */).then(c => wrapFunctional(c.default || c))
export const ProductGallery = () => import('../../components/product/detail/ProductGallery.vue' /* webpackChunkName: "components/product-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductInfo = () => import('../../components/product/detail/ProductInfo.vue' /* webpackChunkName: "components/product-info" */).then(c => wrapFunctional(c.default || c))
export const ProductListFavorite = () => import('../../components/product/list/favorite.vue' /* webpackChunkName: "components/product-list-favorite" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperCategory = () => import('../../components/product/swiper/category.vue' /* webpackChunkName: "components/product-swiper-category" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperGallery = () => import('../../components/product/swiper/gallery.vue' /* webpackChunkName: "components/product-swiper-gallery" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiper = () => import('../../components/product/swiper/index.vue' /* webpackChunkName: "components/product-swiper" */).then(c => wrapFunctional(c.default || c))
export const ProductSwiperRecommend = () => import('../../components/product/swiper/recommend.vue' /* webpackChunkName: "components/product-swiper-recommend" */).then(c => wrapFunctional(c.default || c))
export const SearchFilter = () => import('../../components/search/Filter/index.vue' /* webpackChunkName: "components/search-filter" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchBarInput = () => import('../../components/search/SearchBar/Input.vue' /* webpackChunkName: "components/search-search-bar-input" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchBarPanel = () => import('../../components/search/SearchBar/Panel.vue' /* webpackChunkName: "components/search-search-bar-panel" */).then(c => wrapFunctional(c.default || c))
export const SearchSearchBar = () => import('../../components/search/SearchBar/index.vue' /* webpackChunkName: "components/search-search-bar" */).then(c => wrapFunctional(c.default || c))
export const SearchTrendGroup = () => import('../../components/search/TrendGroup/index.vue' /* webpackChunkName: "components/search-trend-group" */).then(c => wrapFunctional(c.default || c))
export const StoreCard = () => import('../../components/store/card/index.vue' /* webpackChunkName: "components/store-card" */).then(c => wrapFunctional(c.default || c))
export const UserSection = () => import('../../components/user/Section/index.js' /* webpackChunkName: "components/user-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionCommentSection = () => import('../../components/user/Section/CommentSection/index.vue' /* webpackChunkName: "components/user-section-comment-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionFavoSection = () => import('../../components/user/Section/FavoSection/index.vue' /* webpackChunkName: "components/user-section-favo-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionInfoSectionReferrerCode = () => import('../../components/user/Section/InfoSection/ReferrerCode.vue' /* webpackChunkName: "components/user-section-info-section-referrer-code" */).then(c => wrapFunctional(c.default || c))
export const UserSectionInfoSection = () => import('../../components/user/Section/InfoSection/index.vue' /* webpackChunkName: "components/user-section-info-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionMessageCard = () => import('../../components/user/Section/MessageSection/MessageCard.vue' /* webpackChunkName: "components/user-section-message-card" */).then(c => wrapFunctional(c.default || c))
export const UserSectionMessageSection = () => import('../../components/user/Section/MessageSection/index.vue' /* webpackChunkName: "components/user-section-message-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionMessageSetSection = () => import('../../components/user/Section/MessageSetSection/index.vue' /* webpackChunkName: "components/user-section-message-set-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionOrderSection = () => import('../../components/user/Section/OrderSection/index.vue' /* webpackChunkName: "components/user-section-order-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionReferrerSection = () => import('../../components/user/Section/ReferrerSection/index.vue' /* webpackChunkName: "components/user-section-referrer-section" */).then(c => wrapFunctional(c.default || c))
export const UserSectionResetPasswordBlock = () => import('../../components/user/Section/ResetPassword/PasswordBlock.vue' /* webpackChunkName: "components/user-section-reset-password-block" */).then(c => wrapFunctional(c.default || c))
export const UserSectionResetPassword = () => import('../../components/user/Section/ResetPassword/index.vue' /* webpackChunkName: "components/user-section-reset-password" */).then(c => wrapFunctional(c.default || c))
export const UserSectionComment = () => import('../../components/user/Section/CommentSection/Tabs/Comment.vue' /* webpackChunkName: "components/user-section-comment" */).then(c => wrapFunctional(c.default || c))
export const UserSectionCommentSectionTabsHistoryComment = () => import('../../components/user/Section/CommentSection/Tabs/HistoryComment.vue' /* webpackChunkName: "components/user-section-comment-section-tabs-history-comment" */).then(c => wrapFunctional(c.default || c))
export const UserSectionInfoSectionTabsBaseInfo = () => import('../../components/user/Section/InfoSection/Tabs/BaseInfo.vue' /* webpackChunkName: "components/user-section-info-section-tabs-base-info" */).then(c => wrapFunctional(c.default || c))
export const UserSectionInfoSectionTabsRecipient = () => import('../../components/user/Section/InfoSection/Tabs/Recipient.vue' /* webpackChunkName: "components/user-section-info-section-tabs-recipient" */).then(c => wrapFunctional(c.default || c))
export const UserSectionOrder = () => import('../../components/user/Section/OrderSection/Tabs/Order.vue' /* webpackChunkName: "components/user-section-order" */).then(c => wrapFunctional(c.default || c))
export const UserSectionOrderHistory = () => import('../../components/user/Section/OrderSection/Tabs/OrderHistory.vue' /* webpackChunkName: "components/user-section-order-history" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
