export const state = () => ({
  product: {},
  comments: {},
  error_message: '',
  comment_error_message: '',
})

export const mutations = {
  setProduct(state, product) {
    state.product = product
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
  setComment(state, comments) {
    state.comments = comments
  },
  setCommentErrorMessage(state, error_message) {
    state.comment_error_message = error_message
  },
}

export const actions = {
  async getProduct({ commit }, payload) {
    await this.$axios
      .$get(`/product/${payload.id}`)
      .then((response) => {
        let product = response
        commit('setProduct', product)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
  async getComments({ commit }, payload) {
    await this.$axios
      .$get(`/product/${payload.id}/comment?page=${payload.page}`)
      .then((response) => {
        let comments = response
        commit('setComment', comments)
      })
      .catch((error) => {
        commit('setCommentErrorMessage', error)
      })
  },
}
