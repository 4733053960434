export const state = () => ({
  wish_lists: [],
  wish_id_list: [],
  error_message: '',
})

export const mutations = {
  setWishLists(state, wish_lists) {
    state.wish_lists = wish_lists
    state.wish_id_list = wish_lists.map((item) => item.product_id)
  },
  resetWishLists(state) {
    state.wish_lists = []
    state.wish_id_list = []
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  /**
   * @description: Get datas
   * @param {*} dispatch
   * @param {*} commit
   * @param {*} payload
   * @return {*}
   */
  async getWishlist({ commit }) {
    await this.$axios
      .$get('/product_wishlist', {
        params: { query: 'ALL' },
      })
      .then((response) => {
        commit('setWishLists', response)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
  /**
   * @description: resetlist
   * @param {*} dispatch
   * @param {*} commit
   * @param {*} payload
   * @return {*}
   */
  resetWishlist({ commit }) {
    commit('resetWishLists')
  },
  /**
   * @description: Create
   * @param {*} dispatch
   * @param {*} commit
   * @param {*} payload
   * @return {*}
   */
  store({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$post(`/product_wishlist/${payload.id}`)
        .then((response) => {
          dispatch('getWishlist', {
            params: { query: 'ALL' },
          })
          resolve(response)
        })
        .catch((error) => {
          commit('setErrorMessage', error)
          reject(error)
        })
    })
  },

  /**
   * @description: destroy data
   * @param {*} dispatch
   * @param {*} commit
   * @param {*} payload
   * @return {*}
   */
  destroy({ dispatch, commit }, payload) {
    return new Promise((resolve, reject) => {
      this.$axios
        .$delete(`/product_wishlist/${payload.id}`)
        .then((response) => {
          dispatch('getWishlist', {
            params: { query: 'ALL' },
          })
          resolve(response)
        })
        .catch((error) => {
          commit('setErrorMessage', error)
          reject(error)
        })
    })
  },
}
