export const state = () => ({
  error_message: '',
})

export const mutations = {
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async createWishLists({ commit }, { id }) {
    await this.$axios
      .$post(`/product_wishlist/${id}`)
      .then(() => {})
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
