export const state = () => ({
  order_list: {
    list: [],
    current_page: '',
    per_page: '',
    total: '',
  },
  order_history_list: {
    list: [],
    current_page: '',
    per_page: '',
    total: '',
  },
  error_message: '',
})

export const mutations = {
  setOrderList(state, response) {
    state.order_list.list = response.data.data
    state.order_list.current_page = response.data.current_page
    state.order_list.per_page = response.data.per_page
    state.order_list.total = response.data.total
  },
  setOrderHistoryList(state, response) {
    state.order_history_list.list = response.data.data
    state.order_history_list.current_page = response.data.current_page
    state.order_history_list.per_page = response.data.per_page
    state.order_history_list.total = response.data.total
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getOrderList({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/ec-order?page=${payload.page}`)
      commit('setOrderList', response)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
  async getOrderHistoryList({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/ec-order/history?page=${payload.page}`)
      commit('setOrderHistoryList', response)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
}
