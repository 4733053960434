export default function ({ $axios, error: nuxtError, app }) {
  $axios.setBaseURL(process.env.API_ROUTER_URL)
  $axios.onRequest(() => {
    const currency = app.$cookies.get('currency') || 'TWD'
    const localization = app.i18n.locale

    $axios.setHeader('currency', currency)
    $axios.setHeader('X-localization', localization)
    $axios.setHeader('Content-Type', 'application/json')
  })
  $axios.onError((error) => {
    // error handler
    return handlerError(error)

    /**
     * @description: Definded of error message construct.
     * @param {*} error - The error of the axios error data
     * @return {*}
     */
    function handlerMsg(error) {
      return {
        code: error.response.data.error.code ?? null,
        message: error.response.data.error.message,
        detail: error.response.data.error.detail ?? '',
      }
    }

    /**
     * @description: Handler nuxt error.
     * @param {*} http_status - The http_status is api response status code.
     * @return {*}
     */
    function handlerNuxtError(http_status) {
      nuxtError({
        statusCode: http_status,
      })
      return Promise.resolve(false)
    }

    /**
     * @description: Set http status message.
     * @param {*} error - The error of the axios error data
     * @return {*}
     */
    function handlerError(error) {
      let errorMsg = {}

      switch (error.response.status) {
        case 400:
          errorMsg = handlerMsg(error)
          break
        case 401:
          errorMsg = handlerMsg(error)
          break
      }

      // if http status is 404, return 404 page
      if (error.response.status === 404) {
        return handlerNuxtError(error.response.status)
      }

      // if http status is 500, return 500 page
      if (error.response.status === 500) {
        return handlerNuxtError(error.response.status)
      }

      return Promise.reject(errorMsg)
    }
  })
}
