import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      searchWrap: false,
      loginWrap: false,
      last_top: 0,
    }
  },

  computed: {
    ...mapGetters({
      products: 'cart/list',
    }),
    dock_links() {
      return [
        {
          show: true,
          key: 'search',
          icon: 'fas fa-search',
          event: 'toggle-search',
        },
        {
          show: this.$nuxt.$store.state.auth.loggedIn,
          key: 'favorite',
          icon: 'fas fa-heart',
          to: '/user/favorite',
        },
        {
          show: true,
          key: 'cart',
          icon: 'fas fa-shopping-cart',
          to: '/cart',
          badge: this.products.length,
          badge_num: this.products.length,
        },
        {
          show: this.$nuxt.$store.state.auth.loggedIn,
          key: 'message',
          icon: 'fas fa-envelope-open-text',
          to: '/user/message',
        },
        {
          show: this.$nuxt.$store.state.auth.loggedIn,
          key: 'user',
          icon: 'fas fa-user-circle',
          to: '/user/info',
        },
        {
          show: !this.$nuxt.$store.state.auth.loggedIn,
          key: 'login',
          icon: 'fas fa-user-circle',
          event: 'toggle-login',
        },
      ]
    },
  },

  watch: {
    $route() {
      this.$nuxt.$emit('header-collpased', false)
    },
    searchWrap(val) {
      if (val == true) {
        this.$refs.header.showSearchWrap()
      } else {
        this.$refs.header.closeSearchWrap()
      }

      this.dock_links[0].active = val
    },
    loginWrap(val) {
      this.dock_links[5].active = val
    },
  },

  beforeMount() {
    window.addEventListener('scroll', this.handleScroll)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
  },

  mounted() {
    //global events

    this.$nuxt.$on('ja-backdrop', (boolean) => {
      this.modal_open = boolean
      this.bodyScrollLock(boolean)
    })

    this.$nuxt.$on('header-collpased', (boolean) => {
      this.menu_collapsed = boolean
      this.bodyScrollLock(boolean)
    })

    this.$nuxt.$on('toggle-login', (boolean) => {
      this.loginWrap = boolean
    })

    this.$nuxt.$on('lock-body', (boolean) => {
      this.bodyScrollLock(boolean)
    })

    this.$nuxt.$on('toggle-search', (boolean) => {
      this.searchWrap = boolean
    })
  },

  methods: {
    handleScroll() {
      let y = window.pageYOffset
      const contentOffset = document.body.clientHeight - window.innerHeight
      this.dock.active = y == contentOffset ? false : true

      const headerFixOffest = this.$route.name.indexOf('index___') > -1 ? window.innerHeight : 0
      this.header.fixed = y > headerFixOffest ? true : false
    },

    bodyScrollLock(boolean) {
      if (boolean) {
        const x = window.scrollX
        const y = window.scrollY
        window.onscroll = function () {
          window.scrollTo(x, y)
        }
        const html = document.querySelector('html')
        html.style.overflow = 'hidden'
      } else {
        window.onscroll = function () {}
        const html = document.querySelector('html')
        html.style.overflow = 'auto'
      }
    },
  },
}
