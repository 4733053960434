import Vue from 'vue'
import * as Sentry from '@sentry/browser'

// NOTE: this plugin is depends on nuxt-env
export default function (context, inject) {
  const opts = Object.assign({}, {"disabled":false,"dsn":undefined,"environment":undefined,"release":undefined,"defaultIntegrations":[{"name":"Dedupe"},{"options":{},"name":"InboundFilters"},{"name":"FunctionToString"},{"options":{"depth":3},"name":"ExtraErrorData"},{"name":"Console"},{"name":"Http"},{"options":{},"name":"OnUncaughtException","handler":function (error) {
        if (!caughtFirstError) {
            var hub_1 = core_1.getCurrentHub();
            // this is the first uncaught error and the ultimate reason for shutting down
            // we want to do absolutely everything possible to ensure it gets captured
            // also we want to make sure we don't go recursion crazy if more errors happen after this one
            firstError = error;
            caughtFirstError = true;
            if (hub_1.getIntegration(OnUncaughtException)) {
                hub_1.withScope(function (scope) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
                    return tslib_1.__generator(this, function (_a) {
                        scope.setLevel(types_1.Severity.Fatal);
                        hub_1.captureException(error, { originalException: error });
                        if (!calledFatalError) {
                            calledFatalError = true;
                            onFatalError(error);
                        }
                        return [2 /*return*/];
                    });
                }); });
            }
            else {
                if (!calledFatalError) {
                    calledFatalError = true;
                    onFatalError(error);
                }
            }
        }
        else if (calledFatalError) {
            // we hit an error *after* calling onFatalError - pretty boned at this point, just shut it down
            logger_1.logger.warn('uncaught exception after calling fatal error shutdown callback - this is bad! forcing shutdown');
            handlers_1.defaultOnFatalError(error);
        }
        else if (!caughtSecondError) {
            // two cases for how we can hit this branch:
            //   - capturing of first error blew up and we just caught the exception from that
            //     - quit trying to capture, proceed with shutdown
            //   - a second independent error happened while waiting for first error to capture
            //     - want to avoid causing premature shutdown before first error capture finishes
            // it's hard to immediately tell case 1 from case 2 without doing some fancy/questionable domain stuff
            // so let's instead just delay a bit before we proceed with our action here
            // in case 1, we just wait a bit unnecessarily but ultimately do the same thing
            // in case 2, the delay hopefully made us wait long enough for the capture to finish
            // two potential nonideal outcomes:
            //   nonideal case 1: capturing fails fast, we sit around for a few seconds unnecessarily before proceeding correctly by calling onFatalError
            //   nonideal case 2: case 2 happens, 1st error is captured but slowly, timeout completes before capture and we treat second error as the sendErr of (nonexistent) failure from trying to capture first error
            // note that after hitting this branch, we might catch more errors where (caughtSecondError && !calledFatalError)
            //   we ignore them - they don't matter to us, we're just waiting for the second error timeout to finish
            caughtSecondError = true;
            setTimeout(function () {
                if (!calledFatalError) {
                    // it was probably case 1, let's treat err as the sendErr and call onFatalError
                    calledFatalError = true;
                    onFatalError(firstError, error);
                }
                else {
                    // it was probably case 2, our first error finished capturing while we waited, cool, do nothing
                }
            }, timeout); // capturing could take at least sendTimeout to fail, plus an arbitrary second for how long it takes to collect surrounding source etc
        }
    }},{"name":"OnUnhandledRejection"},{"name":"LinkedErrors","key":"cause","limit":5}]}, {
    // Use default browser integrations
    defaultIntegrations: false,
    integrations: [
      new Sentry.Integrations.Dedupe,
      new Sentry.Integrations.InboundFilters,
      new Sentry.Integrations.FunctionToString,
      new Sentry.Integrations.TryCatch,
      new Sentry.Integrations.Breadcrumbs,
      new Sentry.Integrations.GlobalHandlers,
      new Sentry.Integrations.LinkedErrors,
      new Sentry.Integrations.UserAgent,
      new Sentry.Integrations.Vue({ Vue })
    ]
  });

  // Set via nuxt-env if it's exists
  if (context.app.$env.SENTRY_DSN) opts.dsn = context.app.$env.SENTRY_DSN;
  if (context.app.$env.SENTRY_ENVIRONMENT) opts.environment = context.app.$env.SENTRY_ENVIRONMENT;
  if (context.app.$env.SENTRY_RELEASE) opts.release = context.app.$env.SENTRY_RELEASE;

  // Setup sentry
  Sentry.init(opts);

  // Inject Sentry to the context as $sentry
  context.$sentry = Sentry;
  inject('sentry', Sentry);
}
