export const state = () => ({
  comment_list: {
    list: [],
    per_page: '',
    total: '',
    error_message: '',
  },
  comment_history_list: {
    list: [],
    per_page: '',
    total: '',
    error_message: '',
  },
  current_order: {
    order: '',
    error_message: '',
  },
  error_message: '',
})

export const mutations = {
  setCommentList(state, response) {
    state.comment_list.list = response.data
    state.comment_list.per_page = response.per_page
    state.comment_list.total = response.total
  },
  setErrorMessage(state, error_message) {
    state.comment_list.error_message = error_message
  },
  setHistoryList(state, response) {
    state.comment_history_list.list = response.data
    state.comment_history_list.per_page = response.per_page
    state.comment_history_list.total = response.total
  },
  setOrder(state, response) {
    state.current_order = response.data
  },
  setOrderErrorMessage(state, error_message) {
    state.current_order.error_message = error_message
  },
}

export const actions = {
  async getPendingComment({ commit }) {
    try {
      const response = await this.$axios.get('/comment/pending')
      commit('setCommentList', response)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
  async getHistoryComment({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/comment/history?page=${payload.page}`)
      commit('setHistoryList', response)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
  async getOrder({ commit }, payload) {
    try {
      const response = await this.$axios.get(`/comment/pending/${payload.order_id}`)
      commit('setOrder', response)
    } catch (error) {
      commit('setOrderErrorMessage', error)
    }
  },
}
