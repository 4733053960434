//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import layoutMixins from '@/mixins/events'
import CookieGDPRMixins from '@/mixins/gdpr'
import HeaderNavbar from '@/components/header'
import CookieTerms from '@/components/cookie'

export default {
  name: `JafunApp`,
  components: {
    HeaderNavbar,
    CookieTerms,
  },
  mixins: [layoutMixins, CookieGDPRMixins],
  data() {
    return {
      modal_open: false,
      header: {
        fixed: false,
        transparent: false,
      },
      dock: {
        active: true,
      },
    }
  },
}
