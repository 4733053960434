import _ from 'lodash'
import build from '~/libs/store/build'
import crud from '~/libs/store/crud'

export default build(crud(), {
  actions: {
    fetchList({ commit }) {
      return this.$axios({
        url: '/notification-type',
        method: 'GET',
      }).then((res) => {
        const list = _.get(res, 'data', [])
        commit('setList', list)
      })
    },
  },
})
