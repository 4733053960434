//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import SearchInput from '@/components/search/SearchBar/Input'
import SearchPanel from '@/components/search/SearchBar/Panel'
import vClickOutside from 'v-click-outside'

export default {
  components: {
    SearchInput,
    SearchPanel,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    inline: {
      type: Boolean,
      default: false,
    },

    searchActive: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      input: {
        keyword: '',
      },
      search_wrap: false,
      quick_search: false,
      panel_height: 'auto',
    }
  },

  computed: {
    ...mapState('product/search', ['preview_search', 'error_message']),
    tagsGroup() {
      return [
        {
          title: this.$t('search_input.hot_search'),
          tags: [
            { txt: '抹茶' },
            { txt: '巧克力' },
            { txt: '和菓' },
            { txt: '餅乾' },
            { txt: '蛋糕' },
            { txt: '大福' },
          ],
        },
      ]
    },
    placeholder() {
      return this.$t('search_input.placeholder')
    },
  },

  watch: {
    'input.keyword': {
      handler(val) {
        try {
          this.$store.dispatch('product/search/getPreviewSearchProducts', this.input)
        } catch (e) {
          this.error_msg = e
        }

        this.quick_search = val ? true : false
      },
    },
    '$route.query': {
      handler() {
        this.closePanel()
      },
      deep: true,
    },
    search_wrap(val) {
      this.$emit('update:searchActive', val)
    },
  },

  methods: {
    reset() {
      this.$refs.SearchInput.keyword = ''
    },

    handleClickOutside() {
      if (!this.$device.isMobile || this.inline) {
        this.closePanel()
      }
    },

    handleSubmit(keyword) {
      this.$router.push({
        path: this.localePath(`/product/search/`),
        query: { keyword: keyword },
      })

      this.closePanel()
    },

    showPanel() {
      this.search_wrap = true
    },

    closePanel() {
      this.search_wrap = false

      this.panel_height = 'auto'

      const body = document.querySelector('body')
      body.style.position = 'relative'

      this.reset()
    },
  },
}
