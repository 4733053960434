import _ from 'lodash'

const normalizeTransform = (transform) => {
  if (typeof transform === 'function') {
    return transform
  }

  return (data) => data
}

const removeId = (list, id) => {
  return _.cloneDeep(list).filter((item) => {
    if (item.id === id) {
      return false
    }

    if (item.children) {
      item.children = removeId(item.children, id)
    }

    return item
  })
}

export default (transform) => {
  transform = normalizeTransform(transform)

  return {
    state: {
      list: [],
      current_page: 1,
      per_page: 10,
      total: 0,
      data: {},
    },
    mutations: {
      setList(state, list) {
        state.list = list
      },
      setData(state, data) {
        state.data = data
      },
      setCurrentPage(state, current_page) {
        state.current_page = parseInt(current_page, 10)
      },
      setPerPage(state, per_page) {
        state.per_page = parseInt(per_page, 10)
      },
      setTotal(state, total) {
        state.total = parseInt(total, 10)
      },
      updateData(state, data) {
        state.data = {
          ...state.date,
          ...data,
        }
      },
      appendToList(state, list) {
        state.list = state.list.concat(list)
      },
      updateDataToList(state, data) {
        if (!data.id) {
          return
        }

        state.list = state.list.map((item) => {
          if (item.id !== data.id) {
            return item
          }

          return data
        })
      },
      removeDataFromList(state, id) {
        state.list = removeId(state.list, id)
      },
    },
    getters: {
      list({ list }) {
        return list.map((data) => transform(data))
      },
      data({ data }) {
        return transform(data)
      },
      total({ total }) {
        return total
      },
      perPage({ per_page }) {
        return per_page
      },
      currentPage({ curretn_page }) {
        return curretn_page
      },
    },
    actions: {
      resetList({ commit }) {
        commit('setList', [])
      },
    },
  }
}
