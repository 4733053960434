export const state = () => ({
  last_login_error: '',
  count: 0,
})

export const mutations = {
  setLoginSucceed(state) {
    state.count = 0
    state.last_login_error = ''
  },
  setLoginFailed(state) {
    if (state.count < 2) {
      state.count += 1
    } else {
      state.count = 0
      state.last_login_error = new Date()
    }
  },
}

export const actions = {
  async userLogin({ commit }, payload) {
    if (payload === 'SUCCESSED') {
      commit('setLoginSucceed')
    } else {
      commit('setLoginFailed')
    }
  },
}
