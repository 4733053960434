//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    width: {
      type: Number,
      default: 31,
    },
    color: {
      type: String,
      default: '#9B0303',
    },
  },
}
