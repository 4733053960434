//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapActions } from 'vuex'
export default {
  data() {
    return {
      input: {
        email: '',
      },
      count: null,
      timer: null,
      resendable: true,
      resendable_reminder: false,
      oauth: {
        google: 'oauth.google',
        fb: 'oauth.fb',
      },
      message: this.$t('forget_password.toast.success'),
      error_msg: {
        detail: {},
      },
      submit: false,
    }
  },

  computed: {
    ...mapState('auths/forgetPassword', ['last_send']),
  },

  watch: {
    count(val) {
      if (val <= 0) {
        this.resendable_reminder = false
        this.resendable = true
      }
    },
    resendable_reminder(val) {
      if (val) {
        this.handleTicker()
      }
    },
  },

  async mounted() {
    this.checkResendable()
  },

  methods: {
    ...mapActions({
      postForgetPassword: 'auths/forgetPassword/postForgetPassword',
    }),
    handlePasswordForget() {
      if (this.resendable) {
        this.$axios
          .post('/forgot_password', this.input)
          .then((res) => {
            this.submit = true
            if (res) {
              this.$toast.success(this.$t('forget_password.toast.success'))
              this.postForgetPassword()
              this.resendable = false
            } else {
              this.$toast.error(this.$t('forget_password.toast.error'))
            }
            this.handleTicker()
          })
          .catch((e) => {
            this.error_msg = e
          })
      } else {
        this.resendable_reminder = true
      }
    },
    handleTicker() {
      const ticker = setInterval(() => {
        let time = this.$moment().diff(this.last_send, 'seconds')
        this.count = 30 - time
        if (time >= 30) {
          clearInterval(ticker)
        }
      }, 1000)
    },
    handelResendEmail() {
      this.handlePasswordForget()
      this.message = this.$t('forget_password.resend.msg')
    },
    handleContent(mode) {
      this.$emit('handler', mode)
    },
    checkResendable() {
      if (this.last_send) {
        this.resendable = this.$moment().diff(this.last_send, 'seconds') >= 30
        this.resendable_reminder =
          this.$moment().diff(this.last_send, 'seconds') > 32 ? false : true
      }
    },
  },
}
