// Algolia
import algoliasearch from 'algoliasearch/lite'
const search_client = algoliasearch(process.env.ALGOLIA_ID, process.env.ALGOLIA_SECRET)
let search_index = search_client.initIndex(process.env.ALGOLIA_INDEX)

function getFilterString(string, facet) {
  const arr = string.split(',').map((i) => `${facet}:${i}`)
  return `(${arr.toString().replace(/,/g, ' OR ')})`
}

function concatFilterString(object) {
  return Object.values(object).toString().replace(/,/g, ' AND ')
}

export const state = () => ({
  search: {
    products: [],
    products_length: '',
    price_min: 0,
    price_max: 0,
    page: 1,
    page_row: '',
    histsPerPage: 18,
  },
  preview_search: {
    products: [],
    products_length: '',
    local_search: [],
  },
  error_message: '',
  last_index_Name: null,
})

export const mutations = {
  setSearchClient(state, result) {
    state.last_index_Name = result
  },
  setSearchProducts(state, result) {
    state.search.products = result.hits
    state.search.products_length = result.nbHits
    state.search.page = result.page + 1
    state.search.page_row = result.nbPages
    state.search.page_perPage = result.hitsPerPage
  },
  setPreviewSearchProducts(state, result) {
    state.preview_search.products = result.hits
    state.preview_search.products_length = result.nbHits
  },
  setRecentSearch(state, keyword) {
    const empty = keyword == '' || keyword == ' '
    const repeated = state.preview_search.local_search.indexOf(keyword) > -1

    if (!repeated && !empty) {
      state.preview_search.local_search.push(keyword)

      if (state.preview_search.local_search.length > 10) {
        state.preview_search.local_search.shift()
      }
    }
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getSearchClient({ commit }, index) {
    search_index = search_client.initIndex(index)
    commit('setSearchClient', index)
  },
  async getSearchProducts({ state, commit, dispatch }, payload) {
    if (payload.rank && state.last_index_Name != payload.rank) {
      await dispatch('getSearchClient', payload.rank)
    }

    const keyword = payload.keyword ? payload.keyword : ''
    const page = payload.page ? payload.page - 1 : ''
    const facet_group = payload.group && payload.group != 0 ? `trend_group_id:${payload.group}` : ''

    const filters = {
      cate: payload.cate ? getFilterString(payload.cate, 'categories.category.name') : '',
      loc: payload.loc ? getFilterString(payload.loc, 'location.city') : '',
      price: `price:${payload.fprice ? payload.fprice : 0} TO ${
        payload.eprice ? payload.eprice : 5000
      }`,
    }

    Object.keys(filters).forEach(
      (v) => !filters[v] && filters[v] !== undefined && delete filters[v],
    )

    await search_index
      .search(keyword, {
        hitsPerPage: 18,
        page: page,
        filters: concatFilterString(filters),
        facetFilters: facet_group,
      })
      .then((response) => {
        commit('setSearchProducts', response)
        commit('setRecentSearch', keyword)
      })
  },
  async getPreviewSearchProducts({ commit }, payload) {
    await search_index.search(payload.keyword).then((response) => {
      commit('setPreviewSearchProducts', response)
    })
  },
}
