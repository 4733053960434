export const state = () => ({
  gdpr_accept: '',
})

export const mutations = {
  setGDPRAccepted(state, status) {
    state.gdpr_accept = status
  },
}

export const actions = {
  setGDPRAccepted({ commit }) {
    commit('setGDPRAccepted', true)
  },
}
