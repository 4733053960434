export const state = () => ({
  products: [],
  error_message: '',
})

export const mutations = {
  setProducts(state, products) {
    state.products = products
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getProducts({ commit }) {
    await this.$axios
      .$get('/product')
      .then((response) => {
        let products = response
        commit('setProducts', products)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
