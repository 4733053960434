import build from '~/libs/store/build'
import crud from '~/libs/store/crud'

const getProductIndex = (list, id) => list.findIndex((product) => product.id === id)

const transCarts = (list) => {
  return list.reduce((carry, item) => {
    const found = carry.find((product) => product.id === item.product_id)
    if (found) {
      const product = found
      const product_index = getProductIndex(carry, product.id)
      carry[product_index] = {
        ...product,
        definitions: product.definitions.concat([
          {
            id: item.id,
            product_id: product.id,
            label: item.label,
            type: item.type,
            price: item.price,
            quantity: item.quantity,
            image: item.image,
            arrival_date: item.arrival_date,
          },
        ]),
      }
      return carry
    }

    const product = {
      id: item.product_id,
      label: item.label,
      definitions: [
        {
          id: item.id,
          product_id: item.product_id,
          label: item.label,
          type: item.type,
          price: item.price,
          quantity: item.quantity,
          image: item.image,
          arrival_date: item.arrival_date,
        },
      ],
    }
    carry.push(product)
    return carry
  }, [])
}

export default build(crud(), {
  getters: {
    normalizeList({ list }) {
      return transCarts(list)
    },
    cartCount({ list }) {
      return list.reduce((carry, product) => {
        carry = carry + product.quantity
        return carry
      }, 0)
    },
  },
  mutations: {
    setProductToCart(state, value) {
      const found = state.list.find(
        (product) => product.type === value.type && product.product_id === value.product_id,
      )

      if (found) {
        found.quantity = found.quantity + value.quantity
        return
      }

      state.list.push(value)
    },
    updateProductQuantity({ list }, { id, quantity }) {
      const productIndex = list.findIndex((product) => product.id === id)
      if (productIndex < 0) {
        return
      }

      list[productIndex].quantity = quantity
    },
  },
  actions: {
    updateData({ commit }, data) {
      commit('updateData', data)
    },
    setProductToCart({ commit }, value) {
      commit('setProductToCart', value)
    },
    removeProductFromCart({ commit }, id) {
      commit('removeDataFromList', id)
    },
    updateProductQuantity({ commit }, { id, quantity }) {
      commit('updateProductQuantity', { id, quantity })
    },
    resetCart({ commit }) {
      commit('setList', [])
    },
    initCart({ commit, state }) {
      const { list } = state
      if (list.length == 0) {
        return
      }

      this.$axios({
        method: 'POST',
        url: '/init-cart',
        data: {
          items: list,
        },
      }).then((response) => {
        const { data } = response.data
        commit('setList', data)
      })
    },
  },
})
