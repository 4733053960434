export const state = () => ({
  trend_groups: [],
  error_message: '',
})

export const mutations = {
  setTrendGroups(state, trend_groups) {
    state.trend_groups = trend_groups
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getTrendGroups({ commit }) {
    await this.$axios
      .$get('/trend_group')
      .then((response) => {
        let trend_groups = response.data
        commit('setTrendGroups', trend_groups)
      })
      .catch((error) => {
        commit('setErrorMessage', error)
      })
  },
}
