import _ from 'lodash'
import moment from 'moment'
import build from '~/libs/store/build'
import crud from '~/libs/store/crud'

const transData = (data) => {
  return {
    id: data.id,
    title: data.title,
    content: data.content,
    read: data.is_read,
    url: data.url,
    notification: data.notification,
    datetime: moment(data.created_at).format('YYYY-MM-DD HH:mm:ss'),
    time: moment(data.created_at).format('HH:mm'),
    date: moment(data.created_at).format('YYYY/MM/DD'),
  }
}

export default build(crud(transData), {
  state: {
    count: 0,
  },
  getters: {
    count(state) {
      return state.count
    },
  },
  mutations: {
    setCount(state, count) {
      state.count = count
    },
    addCount(state) {
      state.count = state.count + 1
    },
    addTotal(state) {
      state.total = state.total + 1
    },
    appendToListHeadAndPop(state, list) {
      if (state.list.length < 10) {
        state.list = _.concat(list, state.list)

        return
      }

      const newList = _.concat(list, state.list)
      state.list = _.dropRight(newList)
    },
  },
  actions: {
    fetchList({ commit }, { page = 1, per_page = 10 } = {}) {
      return this.$axios({
        url: '/user/notification',
        method: 'GET',
        params: {
          page,
          per_page,
        },
      }).then((res) => {
        const list = _.get(res, 'data.data', [])
        const total = _.get(res, 'data.total', 0)
        const per_page = _.get(res, 'data.per_page', 10)
        const current_page = _.get(res, 'data.current_page', 1)
        commit('setList', list)
        commit('setTotal', total)
        commit('setPerPage', per_page)
        commit('setCurrentPage', current_page)
      })
    },
    fetchRead({ commit }, { id, page }) {
      return this.$axios({
        url: `notification/${id}/read`,
        method: 'POST',
      }).then(() => {
        const current_page = page
        commit('setCurrentPage', current_page)
      })
    },
    fetchUnreadCount({ commit }) {
      return this.$axios({
        url: 'user/unread_notification_count',
        method: 'GET',
      }).then((response) => {
        const count = _.get(response, 'data.data.count', 0)
        commit('setCount', count)
      })
    },
    addNotification({ commit }, notification) {
      commit('appendToListHeadAndPop', notification)
      commit('addCount')
      commit('addTotal')
    },
  },
})
