import Echo from 'laravel-echo'

window.Pusher = require('pusher-js')

window.Echo = new Echo({
  broadcaster: 'pusher',
  authEndpoint: process.env.API_ROUTER_URL + '/broadcasting/auth',
  key: process.env.MIX_PUSHER_APP_KEY,
  cluster: process.env.MIX_PUSHER_APP_CLUSTER,
  wsHost: process.env.MIX_PUSHER_HOST,
  forceTLS: true,
  encrypted: true,
})
