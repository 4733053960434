export default {
  methods: {
    /**
     * Show success toast.
     * @param {string} message
     */
    successToast(message) {
      this.$toast.success(message || this.$t('toast.save.success'))
    },
    /**
     * Show faild toast.
     * @param {string} message
     */
    faildToast(message) {
      this.$toast.error(message || this.$t('toast.save.faild'))
    },
  },
}
