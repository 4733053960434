export const state = () => ({
  categories: {
    list: [],
  },
  products: {
    list: [],
    per_page: '',
    total: '',
  },
  error_message: '',
})

export const mutations = {
  setProductCategory(state, response) {
    state.categories.list = response.data
  },
  setProductWishlist(state, response) {
    state.products.list = response.data
    state.products.per_page = response.per_page
    state.products.total = response.total
  },
  setErrorMessage(state, error_message) {
    state.error_message = error_message
  },
}

export const actions = {
  async getProductCategory({ commit }) {
    try {
      const response = await this.$axios.get('product_wishlist/category')
      commit('setProductCategory', response)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
  async getProductWishlist({ commit }, payload) {
    try {
      const response = await this.$axios.get('product_wishlist', {
        params: payload,
      })
      commit('setProductWishlist', response.data)
    } catch (error) {
      commit('setErrorMessage', error)
    }
  },
}
