import { mapState, mapActions } from 'vuex'
export default {
  computed: {
    ...mapState('gdpr', ['gdpr_accept']),

    cookie_modal() {
      return !this.gdpr_accept
    },
  },

  methods: {
    ...mapActions({
      handleAcceptCookies: 'gdpr/setGDPRAccepted',
    }),
    handleCloseCookieModal() {
      this.handleAcceptCookies()
      this.cookie_modal = false
    },
  },
}
